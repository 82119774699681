import React from "react";
import LinkedInShare from "../../../assets/svg/linkedin2.svg";
import FacebookShare from "../../../assets/svg/facebook.svg";
import TwitterShare from "../../../assets/svg/twitter_share.svg";
import { push_event_to_datalayer } from "../../../tools/analytics";
import * as styles from "./sharebuttons.module.scss";


export default function ShareButtons(props) {

  function shareFacebook() {
    push_event_to_datalayer("facebook-share-button-clicked");
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(props.url),
      "pop",
      "width=600, height=400, scrollbars=no"
    );
    return null;
  }

  function shareTwitter() {
    push_event_to_datalayer("twitter-share-button-clicked");
    window.open(
      "https://twitter.com/share?text=Just used @PreviewedApp to create awesome mockups&?url=" +
        encodeURIComponent(props.url),
      "pop",
      "width=600, height=400, scrollbars=no"
    );
    return null;
  }

  function shareLinkedIn() {
    push_event_to_datalayer("linkedin-share-button-clicked");
    window.open(
      "https://www.linkedin.com/sharing/share-offsite/?url=" + props.url,
      "pop",
      "width=600, height=400, scrollbars=no"
    );
    return null;
  }

  return (
    <div className={styles.socials}>
      <div onClick={shareFacebook}>
        <FacebookShare />
      </div>
      <div onClick={shareTwitter}>
        <TwitterShare/>
      </div>
      <div onClick={shareLinkedIn} >
        <LinkedInShare />
      </div>
    </div>
  );
}
