import React from "react";
// import { push_event_to_datalayer } from "../../../../../../tools/analytics";
import CheckIcon from '../../../../assets/svg/check.svg';
import CopyIcon from '../../../../assets/svg/copy.svg';

import * as styles from "./urlcopy.module.scss";

export default (props) => {
  const [copy_text, setCopyText] = React.useState('')
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
    setCopyText(text)
  }

  return (
    <div className={styles.urlcopyContainer}>
      <div className={styles.copyText}>
          <span>
            {props.url}
          </span>
      </div>
      <button className={styles.attributionButton} onClick={() => handleCopy(props.url)}>
          {copy_text === props.url ?
          <CheckIcon className={styles.check} />:
          <>
          <CopyIcon className={styles.copy} />
          Copy
          </>}
      </button>
    </div>
  )
}

