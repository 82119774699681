// extracted by mini-css-extract-plugin
export const outer = "detail-module--outer--LMhcV";
export const container = "detail-module--container--5-RpU";
export const main = "detail-module--main--qs-18";
export const shareContainer = "detail-module--share-container--32imP";
export const sidebar = "detail-module--sidebar--3QsR5";
export const description = "detail-module--description--1pBjw";
export const editButton = "detail-module--edit-button--3O280";
export const feature = "detail-module--feature--34Htx";
export const leftSide = "detail-module--left-side--3Zm6J";
export const rightSide = "detail-module--right-side--G2AKm";