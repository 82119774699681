import { StaticImage } from "gatsby-plugin-image";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs"
import ShareButtons from "../../../Common/ShareButtons/ShareButtons"
import UrlCopy from "../../../Common/ShareButtons/UrlCopy/UrlCopy"
import ExpandIcon from "../../../../assets/svg/expand.svg"
import DimensionsIcon from "../../../../assets/svg/homepage/dimensions.svg"
import AppStoreVideoSvg from '../../../../assets/svg/menu/appstore-video.svg'
import ThreeMockups from '../../../../assets/svg/menu/ThreeMockups.svg'
import FileIcon from '../../../../assets/svg/homepage/file.svg';
import EditIcon from "../../../../assets/svg/video-step-2.svg";
import TimeIcon from "../../../../assets/svg/time2.svg";
import Firebase from '../../../../firebase/index'
import API from '../../../../firebase/api'

import React, { useEffect, useState } from "react";
import * as styles from "./detail.module.scss";
import Layout from "../../../layout";
import Templates from "../../CategoryTemplate/Templates/Templates";
import { updateUUID } from "../../../Common/Functions";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import { setCanvasStyle } from "../../../../slices/canvas-slice";
import { setCanvas } from "../../../../slices/three-slice";
import { setThreeDCanvasStyle } from "../../../../slices/threed-canvas-screenshot";

import Button from '../../../Common/Button/Button'
import SEO from "../../../seo";


const Feature = (props) => {
  return (
  <div className={styles.feature}>
    <div className={styles.leftSide}>
      {<props.icon/>}
      {props.name}
    </div>
    <div className={styles.rightSide}>
      {props.value}
    </div>
  </div>
  )
}

const TemplateDetail = ({pageContext}) => {
  const dispatch = useDispatch()
  const [visual, setVisual] = useState({})
  const shareUrl = typeof window !== 'undefined' ? window.location.href : 'https://previewed.app/'

  async function loadTemplate() {
    let {id, type, version_id} = pageContext
    let style = updateUUID(await Firebase.getTemplateStyle(id))
    style.name = null // Set name to null
    style.originalTemplateId = id

    API.logTemplateActivity({template_id: id, type: 'detail_edit'})

    if(type === '2d') { 
      dispatch(setCanvasStyle(style));
      navigate("/editor/2D");
    }
    else{
      if(version_id === 2){
        dispatch(setCanvas(style));
        setTimeout(() => {
          window.location = '/editor/3D'
      }, 200)
      }
      else{
        dispatch(setThreeDCanvasStyle(style))
        navigate('/editor/3D-depreciated')
      }
    }
  }

  useEffect(() => {
    (async () => {
      API.logTemplateActivity({template_id: pageContext.id, type: 'detail_view'})
      // Download template image/video from firebase
      let src = await Firebase.getTemplateThumbnail(
        pageContext.thumbnail
      )
      let format = pageContext.thumbnail.includes('.mp4') ? 'video' : 'image'
      setVisual({src, format})
    })()
  }, [])

  return (
    <Layout>
      <SEO title={pageContext.name} description={pageContext.description}/>
      <div className={styles.outer}>
      <div className={styles.container}>
        <div className={styles.main}>
          {visual.src ? visual.format === 'image' ?
              <img src={visual.src} alt={pageContext.name}/> :
              <video muted loop autoPlay src={visual.src} alt={pageContext.name} /> : null}
          <div className={styles.shareContainer}>
            <h5>Share this template 💎</h5>
            <UrlCopy 
              url={shareUrl}
            />
            <ShareButtons url={shareUrl} />
          </div>
        </div>
        <div className={styles.sidebar}>
          <div className={styles.breadcrumbs}>
            <Breadcrumbs breadcrumbs={pageContext.breadcrumbs}  ignore_last={false} />
          </div>
          <h1>{pageContext.name}</h1>
          <div className={styles.features}>
            <Feature
              icon={TimeIcon}
              name='Updated date'
              value={new Date(pageContext.updated_ts).toLocaleString('en-US',
              {
                  month: 'long',
                  year: 'numeric'
              })}
            />
            <Feature
              icon={ExpandIcon}
              name='Size'
              value={`${pageContext.width}x${pageContext.height} px`}
            />
            <Feature
              icon={ThreeMockups}
              name='Dimensionality'
              value={pageContext.type === '2d' ? '2D' : '3D'}
            />
            <Feature
              icon={AppStoreVideoSvg}
              name='Animated'
              value={pageContext.animated ? 'Yes' : 'No'}
            />
            <Feature
              icon={EditIcon}
              name='Customizable'
              value='Yes'
            />
          </div>
          {pageContext.description ?
          <p className={styles.description}>
            {pageContext.description}
          </p> : null}
          <Button className={styles.editButton} onClick={loadTemplate}>
            Edit Template
          </Button>
          <ul>
            <li>
              This mockup template (even with modifications) requires attribution for use in both personal and commercial projects.
            </li>
            <li>
              Free to use, customize, download and distribute with attribution.
            </li>
          </ul>
        </div>
      </div>
      <Templates slug={pageContext.slug} title={'You might also like'} showFilters={false} minHeight={200}/>
      </div>
    </Layout>
  );
};

export default TemplateDetail;



